import { default as _91_46_46_46all_93mSThrSHDzZMeta } from "/src/pages/[...all].vue?macro=true";
import { default as aboutaC0FMxDxqnMeta } from "/src/pages/about.vue?macro=true";
import { default as aboutUsBoPNsvFtvJMeta } from "/src/pages/aboutUs.vue?macro=true";
import { default as indexLwrsBrKi4FMeta } from "/src/pages/account/addresses/[id]/index.vue?macro=true";
import { default as index3uGRvusLjeMeta } from "/src/pages/account/addresses/index.vue?macro=true";
import { default as index3tHoIIVjXTMeta } from "/src/pages/account/alerts/index.vue?macro=true";
import { default as indexVYa2CWNU8gMeta } from "/src/pages/account/cashbackWallets/index.vue?macro=true";
import { default as indexKF6Iq5bhEuMeta } from "/src/pages/account/cashWallets/index.vue?macro=true";
import { default as indexXLgtZqhAbmMeta } from "/src/pages/account/claimVouchers/index.vue?macro=true";
import { default as indexPnHySMZOhvMeta } from "/src/pages/account/commWallets/index.vue?macro=true";
import { default as indexEvhf83RONgMeta } from "/src/pages/account/creditSlips/index.vue?macro=true";
import { default as index51DTgNvzCfMeta } from "/src/pages/account/favoriteProducts/index.vue?macro=true";
import { default as indexP8StgJOSKzMeta } from "/src/pages/account/index.vue?macro=true";
import { default as index_46clientkleHcLbEN9Meta } from "/src/pages/account/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.13_@unocss+reset_5dn2se66p6z3i25vxejh3gni7y/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as index0VDY57YtYxMeta } from "/src/pages/account/loyaltyPoints/index.vue?macro=true";
import { default as indexLBZoksw3BVMeta } from "/src/pages/account/merchandiseReturns/index.vue?macro=true";
import { default as indext9RfoDJxERMeta } from "/src/pages/account/orderHistories/[channelOrderId]/index.vue?macro=true";
import { default as indexvyo7Kp61FWMeta } from "/src/pages/account/orderHistories/[channelOrderId]/invoice/[invoiceId]/index.vue?macro=true";
import { default as indexSy0jKFJgiGMeta } from "/src/pages/account/orderHistories/index.vue?macro=true";
import { default as indexZJ8SrZSXywMeta } from "/src/pages/account/personalInformation/index.vue?macro=true";
import { default as indexEQPumiJKXoMeta } from "/src/pages/account/profile/index.vue?macro=true";
import { default as indexaWWqHLB0zcMeta } from "/src/pages/account/salesReports/[orderreport_id]/index.vue?macro=true";
import { default as indexUJt0p5ND37Meta } from "/src/pages/account/salesReports/index.vue?macro=true";
import { default as index05kW92kaidMeta } from "/src/pages/account/vouchers/index.vue?macro=true";
import { default as account2O7nhTFNyi9Meta } from "/src/pages/account2.vue?macro=true";
import { default as indexv6ITSWGvl1Meta } from "/src/pages/b/outlet/index.vue?macro=true";
import { default as indexi7rGOMQZmLMeta } from "/src/pages/c/[id]/[id2]/[id3]/[id4]/index.vue?macro=true";
import { default as index7FiXttik3wMeta } from "/src/pages/c/[id]/[id2]/[id3]/index.vue?macro=true";
import { default as indexiqKYCdzfJpMeta } from "/src/pages/c/[id]/[id2]/index.vue?macro=true";
import { default as indexytwX35VDD2Meta } from "/src/pages/c/[id]/index.vue?macro=true";
import { default as indexbZlcfQgrT0Meta } from "/src/pages/c/ce/index.vue?macro=true";
import { default as indexwlOb97LCeyMeta } from "/src/pages/c/iluvmyface/index.vue?macro=true";
import { default as indexC8ZVIwyiDrMeta } from "/src/pages/c/sales/index.vue?macro=true";
import { default as index7KaBerG6IdMeta } from "/src/pages/c/skincare/index.vue?macro=true";
import { default as indexvY9Hdn3gp4Meta } from "/src/pages/c/skinTherapies/index.vue?macro=true";
import { default as indexLFDKPQt1q2Meta } from "/src/pages/c/spaWellness/index.vue?macro=true";
import { default as index44HIIjBKTRMeta } from "/src/pages/c/therapies/index.vue?macro=true";
import { default as index61vVd9N6PzMeta } from "/src/pages/cart/index.vue?macro=true";
import { default as index_46client3ayMtOerIOMeta } from "/src/pages/cart/index.client.vue?macro=true";
import { default as cataloguemDiJATGFLQMeta } from "/src/pages/catalogue.vue?macro=true";
import { default as index4E3N5yzW7cMeta } from "/src/pages/checkout/index.vue?macro=true";
import { default as index_46clientHkhDe08HgSMeta } from "/src/pages/checkout/index.client.vue?macro=true";
import { default as paymentpRiiCb5uX2Meta } from "/src/pages/checkout/payment.vue?macro=true";
import { default as successwcsqw1E5wSMeta } from "/src/pages/checkout/success.vue?macro=true";
import { default as contactjt1IjhjjXuMeta } from "/src/pages/contact.vue?macro=true";
import { default as deliveryPolicyV4Rtk0xb6MMeta } from "/src/pages/deliveryPolicy.vue?macro=true";
import { default as deliveryPriceXrIE2dtqadMeta } from "/src/pages/deliveryPrice.vue?macro=true";
import { default as faqytbxbScGkeMeta } from "/src/pages/faq.vue?macro=true";
import { default as indexCJfkQgdimFMeta } from "/src/pages/favorites/index.vue?macro=true";
import { default as howtobuyoNLrI8NF4nMeta } from "/src/pages/howtobuy.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as indexz0ZORWmtqyMeta } from "/src/pages/login/index.vue?macro=true";
import { default as reset_45doL8H9laQMNNMeta } from "/src/pages/login/reset-do.vue?macro=true";
import { default as reset2oRE49G5qqMeta } from "/src/pages/login/reset.vue?macro=true";
import { default as logoutJAIe3EGR5DMeta } from "/src/pages/logout.vue?macro=true";
import { default as _202406_45shipment6erSHsj8boMeta } from "/src/pages/news/202406-shipment.vue?macro=true";
import { default as cancelYQ53yrpTKjMeta } from "/src/pages/payment/cancel.vue?macro=true";
import { default as successGMygimXkW8Meta } from "/src/pages/payment/success.vue?macro=true";
import { default as privacyPolicyHrlvlkjJ6DMeta } from "/src/pages/privacyPolicy.vue?macro=true";
import { default as _91id_93E94BWUgd9ZMeta } from "/src/pages/products/[id].vue?macro=true";
import { default as indexcyRXhHrH9pMeta } from "/src/pages/products/index.vue?macro=true";
import { default as indexoOhunJ3bITMeta } from "/src/pages/register/index.vue?macro=true";
import { default as returnPolicyhvXIBy7uTPMeta } from "/src/pages/returnPolicy.vue?macro=true";
import { default as saleseVNbyOZ5hSMeta } from "/src/pages/sales.vue?macro=true";
import { default as index6KfTDieHyuMeta } from "/src/pages/search/index.vue?macro=true";
import { default as termsOfUseCYLALZXHHqMeta } from "/src/pages/termsOfUse.vue?macro=true";
import { default as tipsAdvicekJ7fK5YR7BMeta } from "/src/pages/tipsAdvice.vue?macro=true";
import { default as whyusMwyGXzZgcsMeta } from "/src/pages/whyus.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93mSThrSHDzZMeta?.name ?? "all",
    path: _91_46_46_46all_93mSThrSHDzZMeta?.path ?? "/:all(.*)*",
    meta: { ...(_91_46_46_46all_93mSThrSHDzZMeta || {}), ...{"middleware":["client"]} },
    alias: _91_46_46_46all_93mSThrSHDzZMeta?.alias || [],
    redirect: _91_46_46_46all_93mSThrSHDzZMeta?.redirect,
    component: () => import("/src/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: aboutaC0FMxDxqnMeta?.name ?? "about",
    path: aboutaC0FMxDxqnMeta?.path ?? "/about",
    meta: { ...(aboutaC0FMxDxqnMeta || {}), ...{"middleware":["client"]} },
    alias: aboutaC0FMxDxqnMeta?.alias || [],
    redirect: aboutaC0FMxDxqnMeta?.redirect,
    component: () => import("/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutUsBoPNsvFtvJMeta?.name ?? "aboutUs",
    path: aboutUsBoPNsvFtvJMeta?.path ?? "/aboutUs",
    meta: { ...(aboutUsBoPNsvFtvJMeta || {}), ...{"middleware":["client"]} },
    alias: aboutUsBoPNsvFtvJMeta?.alias || [],
    redirect: aboutUsBoPNsvFtvJMeta?.redirect,
    component: () => import("/src/pages/aboutUs.vue").then(m => m.default || m)
  },
  {
    name: indexLwrsBrKi4FMeta?.name ?? "account-addresses-id",
    path: indexLwrsBrKi4FMeta?.path ?? "/account/addresses/:id()",
    meta: { ...(indexLwrsBrKi4FMeta || {}), ...{"middleware":["client"]} },
    alias: indexLwrsBrKi4FMeta?.alias || [],
    redirect: indexLwrsBrKi4FMeta?.redirect,
    component: () => import("/src/pages/account/addresses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index3uGRvusLjeMeta?.name ?? "account-addresses",
    path: index3uGRvusLjeMeta?.path ?? "/account/addresses",
    meta: { ...(index3uGRvusLjeMeta || {}), ...{"middleware":["client"]} },
    alias: index3uGRvusLjeMeta?.alias || [],
    redirect: index3uGRvusLjeMeta?.redirect,
    component: () => import("/src/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: index3tHoIIVjXTMeta?.name ?? "account-alerts",
    path: index3tHoIIVjXTMeta?.path ?? "/account/alerts",
    meta: { ...(index3tHoIIVjXTMeta || {}), ...{"middleware":["client"]} },
    alias: index3tHoIIVjXTMeta?.alias || [],
    redirect: index3tHoIIVjXTMeta?.redirect,
    component: () => import("/src/pages/account/alerts/index.vue").then(m => m.default || m)
  },
  {
    name: indexVYa2CWNU8gMeta?.name ?? "account-cashbackWallets",
    path: indexVYa2CWNU8gMeta?.path ?? "/account/cashbackWallets",
    meta: { ...(indexVYa2CWNU8gMeta || {}), ...{"middleware":["client"]} },
    alias: indexVYa2CWNU8gMeta?.alias || [],
    redirect: indexVYa2CWNU8gMeta?.redirect,
    component: () => import("/src/pages/account/cashbackWallets/index.vue").then(m => m.default || m)
  },
  {
    name: indexKF6Iq5bhEuMeta?.name ?? "account-cashWallets",
    path: indexKF6Iq5bhEuMeta?.path ?? "/account/cashWallets",
    meta: { ...(indexKF6Iq5bhEuMeta || {}), ...{"middleware":["client"]} },
    alias: indexKF6Iq5bhEuMeta?.alias || [],
    redirect: indexKF6Iq5bhEuMeta?.redirect,
    component: () => import("/src/pages/account/cashWallets/index.vue").then(m => m.default || m)
  },
  {
    name: indexXLgtZqhAbmMeta?.name ?? "account-claimVouchers",
    path: indexXLgtZqhAbmMeta?.path ?? "/account/claimVouchers",
    meta: { ...(indexXLgtZqhAbmMeta || {}), ...{"middleware":["client"]} },
    alias: indexXLgtZqhAbmMeta?.alias || [],
    redirect: indexXLgtZqhAbmMeta?.redirect,
    component: () => import("/src/pages/account/claimVouchers/index.vue").then(m => m.default || m)
  },
  {
    name: indexPnHySMZOhvMeta?.name ?? "account-commWallets",
    path: indexPnHySMZOhvMeta?.path ?? "/account/commWallets",
    meta: { ...(indexPnHySMZOhvMeta || {}), ...{"middleware":["client"]} },
    alias: indexPnHySMZOhvMeta?.alias || [],
    redirect: indexPnHySMZOhvMeta?.redirect,
    component: () => import("/src/pages/account/commWallets/index.vue").then(m => m.default || m)
  },
  {
    name: indexEvhf83RONgMeta?.name ?? "account-creditSlips",
    path: indexEvhf83RONgMeta?.path ?? "/account/creditSlips",
    meta: { ...(indexEvhf83RONgMeta || {}), ...{"middleware":["client"]} },
    alias: indexEvhf83RONgMeta?.alias || [],
    redirect: indexEvhf83RONgMeta?.redirect,
    component: () => import("/src/pages/account/creditSlips/index.vue").then(m => m.default || m)
  },
  {
    name: index51DTgNvzCfMeta?.name ?? "account-favoriteProducts",
    path: index51DTgNvzCfMeta?.path ?? "/account/favoriteProducts",
    meta: { ...(index51DTgNvzCfMeta || {}), ...{"middleware":["client"]} },
    alias: index51DTgNvzCfMeta?.alias || [],
    redirect: index51DTgNvzCfMeta?.redirect,
    component: () => import("/src/pages/account/favoriteProducts/index.vue").then(m => m.default || m)
  },
  {
    name: index_46clientkleHcLbEN9Meta?.name ?? undefined,
    path: index_46clientkleHcLbEN9Meta?.path ?? "/account",
    meta: { ...(index_46clientkleHcLbEN9Meta || {}), ...{"middleware":["client"]} },
    alias: index_46clientkleHcLbEN9Meta?.alias || [],
    redirect: index_46clientkleHcLbEN9Meta?.redirect,
    component: () => createClientPage(() => import("/src/pages/account/index.client.vue").then(m => m.default || m)),
    children: [
  {
    name: indexP8StgJOSKzMeta?.name ?? "account",
    path: indexP8StgJOSKzMeta?.path ?? "",
    meta: { ...(indexP8StgJOSKzMeta || {}), ...{"middleware":["client"]} },
    alias: indexP8StgJOSKzMeta?.alias || [],
    redirect: indexP8StgJOSKzMeta?.redirect,
    component: () => import("/src/pages/account/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index0VDY57YtYxMeta?.name ?? "account-loyaltyPoints",
    path: index0VDY57YtYxMeta?.path ?? "/account/loyaltyPoints",
    meta: { ...(index0VDY57YtYxMeta || {}), ...{"middleware":["client"]} },
    alias: index0VDY57YtYxMeta?.alias || [],
    redirect: index0VDY57YtYxMeta?.redirect,
    component: () => import("/src/pages/account/loyaltyPoints/index.vue").then(m => m.default || m)
  },
  {
    name: indexLBZoksw3BVMeta?.name ?? "account-merchandiseReturns",
    path: indexLBZoksw3BVMeta?.path ?? "/account/merchandiseReturns",
    meta: { ...(indexLBZoksw3BVMeta || {}), ...{"middleware":["client"]} },
    alias: indexLBZoksw3BVMeta?.alias || [],
    redirect: indexLBZoksw3BVMeta?.redirect,
    component: () => import("/src/pages/account/merchandiseReturns/index.vue").then(m => m.default || m)
  },
  {
    name: indext9RfoDJxERMeta?.name ?? "account-orderHistories-channelOrderId",
    path: indext9RfoDJxERMeta?.path ?? "/account/orderHistories/:channelOrderId()",
    meta: { ...(indext9RfoDJxERMeta || {}), ...{"middleware":["client"]} },
    alias: indext9RfoDJxERMeta?.alias || [],
    redirect: indext9RfoDJxERMeta?.redirect,
    component: () => import("/src/pages/account/orderHistories/[channelOrderId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvyo7Kp61FWMeta?.name ?? "account-orderHistories-channelOrderId-invoice-invoiceId",
    path: indexvyo7Kp61FWMeta?.path ?? "/account/orderHistories/:channelOrderId()/invoice/:invoiceId()",
    meta: { ...(indexvyo7Kp61FWMeta || {}), ...{"middleware":["client"]} },
    alias: indexvyo7Kp61FWMeta?.alias || [],
    redirect: indexvyo7Kp61FWMeta?.redirect,
    component: () => import("/src/pages/account/orderHistories/[channelOrderId]/invoice/[invoiceId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSy0jKFJgiGMeta?.name ?? "account-orderHistories",
    path: indexSy0jKFJgiGMeta?.path ?? "/account/orderHistories",
    meta: { ...(indexSy0jKFJgiGMeta || {}), ...{"middleware":["client"]} },
    alias: indexSy0jKFJgiGMeta?.alias || [],
    redirect: indexSy0jKFJgiGMeta?.redirect,
    component: () => import("/src/pages/account/orderHistories/index.vue").then(m => m.default || m)
  },
  {
    name: indexZJ8SrZSXywMeta?.name ?? "account-personalInformation",
    path: indexZJ8SrZSXywMeta?.path ?? "/account/personalInformation",
    meta: { ...(indexZJ8SrZSXywMeta || {}), ...{"middleware":["client"]} },
    alias: indexZJ8SrZSXywMeta?.alias || [],
    redirect: indexZJ8SrZSXywMeta?.redirect,
    component: () => import("/src/pages/account/personalInformation/index.vue").then(m => m.default || m)
  },
  {
    name: indexEQPumiJKXoMeta?.name ?? "account-profile",
    path: indexEQPumiJKXoMeta?.path ?? "/account/profile",
    meta: { ...(indexEQPumiJKXoMeta || {}), ...{"middleware":["client"]} },
    alias: indexEQPumiJKXoMeta?.alias || [],
    redirect: indexEQPumiJKXoMeta?.redirect,
    component: () => import("/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWWqHLB0zcMeta?.name ?? "account-salesReports-orderreport_id",
    path: indexaWWqHLB0zcMeta?.path ?? "/account/salesReports/:orderreport_id()",
    meta: { ...(indexaWWqHLB0zcMeta || {}), ...{"middleware":["client"]} },
    alias: indexaWWqHLB0zcMeta?.alias || [],
    redirect: indexaWWqHLB0zcMeta?.redirect,
    component: () => import("/src/pages/account/salesReports/[orderreport_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUJt0p5ND37Meta?.name ?? "account-salesReports",
    path: indexUJt0p5ND37Meta?.path ?? "/account/salesReports",
    meta: { ...(indexUJt0p5ND37Meta || {}), ...{"middleware":["client"]} },
    alias: indexUJt0p5ND37Meta?.alias || [],
    redirect: indexUJt0p5ND37Meta?.redirect,
    component: () => import("/src/pages/account/salesReports/index.vue").then(m => m.default || m)
  },
  {
    name: index05kW92kaidMeta?.name ?? "account-vouchers",
    path: index05kW92kaidMeta?.path ?? "/account/vouchers",
    meta: { ...(index05kW92kaidMeta || {}), ...{"middleware":["client"]} },
    alias: index05kW92kaidMeta?.alias || [],
    redirect: index05kW92kaidMeta?.redirect,
    component: () => import("/src/pages/account/vouchers/index.vue").then(m => m.default || m)
  },
  {
    name: account2O7nhTFNyi9Meta?.name ?? "account2",
    path: account2O7nhTFNyi9Meta?.path ?? "/account2",
    meta: { ...(account2O7nhTFNyi9Meta || {}), ...{"middleware":["client"]} },
    alias: account2O7nhTFNyi9Meta?.alias || [],
    redirect: account2O7nhTFNyi9Meta?.redirect,
    component: () => import("/src/pages/account2.vue").then(m => m.default || m)
  },
  {
    name: indexv6ITSWGvl1Meta?.name ?? "b-outlet",
    path: indexv6ITSWGvl1Meta?.path ?? "/b/outlet",
    meta: { ...(indexv6ITSWGvl1Meta || {}), ...{"middleware":["client"]} },
    alias: indexv6ITSWGvl1Meta?.alias || [],
    redirect: indexv6ITSWGvl1Meta?.redirect,
    component: () => import("/src/pages/b/outlet/index.vue").then(m => m.default || m)
  },
  {
    name: indexi7rGOMQZmLMeta?.name ?? "c-id-id2-id3-id4",
    path: indexi7rGOMQZmLMeta?.path ?? "/c/:id()/:id2()/:id3()/:id4()",
    meta: { ...(indexi7rGOMQZmLMeta || {}), ...{"middleware":["client"]} },
    alias: indexi7rGOMQZmLMeta?.alias || [],
    redirect: indexi7rGOMQZmLMeta?.redirect,
    component: () => import("/src/pages/c/[id]/[id2]/[id3]/[id4]/index.vue").then(m => m.default || m)
  },
  {
    name: index7FiXttik3wMeta?.name ?? "c-id-id2-id3",
    path: index7FiXttik3wMeta?.path ?? "/c/:id()/:id2()/:id3()",
    meta: { ...(index7FiXttik3wMeta || {}), ...{"middleware":["client"]} },
    alias: index7FiXttik3wMeta?.alias || [],
    redirect: index7FiXttik3wMeta?.redirect,
    component: () => import("/src/pages/c/[id]/[id2]/[id3]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiqKYCdzfJpMeta?.name ?? "c-id-id2",
    path: indexiqKYCdzfJpMeta?.path ?? "/c/:id()/:id2()",
    meta: { ...(indexiqKYCdzfJpMeta || {}), ...{"middleware":["client"]} },
    alias: indexiqKYCdzfJpMeta?.alias || [],
    redirect: indexiqKYCdzfJpMeta?.redirect,
    component: () => import("/src/pages/c/[id]/[id2]/index.vue").then(m => m.default || m)
  },
  {
    name: indexytwX35VDD2Meta?.name ?? "c-id",
    path: indexytwX35VDD2Meta?.path ?? "/c/:id()",
    meta: { ...(indexytwX35VDD2Meta || {}), ...{"middleware":["client"]} },
    alias: indexytwX35VDD2Meta?.alias || [],
    redirect: indexytwX35VDD2Meta?.redirect,
    component: () => import("/src/pages/c/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbZlcfQgrT0Meta?.name ?? "c-ce",
    path: indexbZlcfQgrT0Meta?.path ?? "/c/ce",
    meta: { ...(indexbZlcfQgrT0Meta || {}), ...{"middleware":["client"]} },
    alias: indexbZlcfQgrT0Meta?.alias || [],
    redirect: indexbZlcfQgrT0Meta?.redirect,
    component: () => import("/src/pages/c/ce/index.vue").then(m => m.default || m)
  },
  {
    name: indexwlOb97LCeyMeta?.name ?? "c-iluvmyface",
    path: indexwlOb97LCeyMeta?.path ?? "/c/iluvmyface",
    meta: { ...(indexwlOb97LCeyMeta || {}), ...{"middleware":["client"]} },
    alias: indexwlOb97LCeyMeta?.alias || [],
    redirect: indexwlOb97LCeyMeta?.redirect,
    component: () => import("/src/pages/c/iluvmyface/index.vue").then(m => m.default || m)
  },
  {
    name: indexC8ZVIwyiDrMeta?.name ?? "c-sales",
    path: indexC8ZVIwyiDrMeta?.path ?? "/c/sales",
    meta: { ...(indexC8ZVIwyiDrMeta || {}), ...{"middleware":["client"]} },
    alias: indexC8ZVIwyiDrMeta?.alias || [],
    redirect: indexC8ZVIwyiDrMeta?.redirect,
    component: () => import("/src/pages/c/sales/index.vue").then(m => m.default || m)
  },
  {
    name: index7KaBerG6IdMeta?.name ?? "c-skincare",
    path: index7KaBerG6IdMeta?.path ?? "/c/skincare",
    meta: { ...(index7KaBerG6IdMeta || {}), ...{"middleware":["client"]} },
    alias: index7KaBerG6IdMeta?.alias || [],
    redirect: index7KaBerG6IdMeta?.redirect,
    component: () => import("/src/pages/c/skincare/index.vue").then(m => m.default || m)
  },
  {
    name: indexvY9Hdn3gp4Meta?.name ?? "c-skinTherapies",
    path: indexvY9Hdn3gp4Meta?.path ?? "/c/skinTherapies",
    meta: { ...(indexvY9Hdn3gp4Meta || {}), ...{"middleware":["client"]} },
    alias: indexvY9Hdn3gp4Meta?.alias || [],
    redirect: indexvY9Hdn3gp4Meta?.redirect,
    component: () => import("/src/pages/c/skinTherapies/index.vue").then(m => m.default || m)
  },
  {
    name: indexLFDKPQt1q2Meta?.name ?? "c-spaWellness",
    path: indexLFDKPQt1q2Meta?.path ?? "/c/spaWellness",
    meta: { ...(indexLFDKPQt1q2Meta || {}), ...{"middleware":["client"]} },
    alias: indexLFDKPQt1q2Meta?.alias || [],
    redirect: indexLFDKPQt1q2Meta?.redirect,
    component: () => import("/src/pages/c/spaWellness/index.vue").then(m => m.default || m)
  },
  {
    name: index44HIIjBKTRMeta?.name ?? "c-therapies",
    path: index44HIIjBKTRMeta?.path ?? "/c/therapies",
    meta: { ...(index44HIIjBKTRMeta || {}), ...{"middleware":["client"]} },
    alias: index44HIIjBKTRMeta?.alias || [],
    redirect: index44HIIjBKTRMeta?.redirect,
    component: () => import("/src/pages/c/therapies/index.vue").then(m => m.default || m)
  },
  {
    name: index_46client3ayMtOerIOMeta?.name ?? undefined,
    path: index_46client3ayMtOerIOMeta?.path ?? "/cart",
    meta: { ...(index_46client3ayMtOerIOMeta || {}), ...{"middleware":["client"]} },
    alias: index_46client3ayMtOerIOMeta?.alias || [],
    redirect: index_46client3ayMtOerIOMeta?.redirect,
    component: () => createClientPage(() => import("/src/pages/cart/index.client.vue").then(m => m.default || m)),
    children: [
  {
    name: index61vVd9N6PzMeta?.name ?? "cart",
    path: index61vVd9N6PzMeta?.path ?? "",
    meta: { ...(index61vVd9N6PzMeta || {}), ...{"middleware":["client"]} },
    alias: index61vVd9N6PzMeta?.alias || [],
    redirect: index61vVd9N6PzMeta?.redirect,
    component: () => import("/src/pages/cart/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: cataloguemDiJATGFLQMeta?.name ?? "catalogue",
    path: cataloguemDiJATGFLQMeta?.path ?? "/catalogue",
    meta: { ...(cataloguemDiJATGFLQMeta || {}), ...{"middleware":["client"]} },
    alias: cataloguemDiJATGFLQMeta?.alias || [],
    redirect: cataloguemDiJATGFLQMeta?.redirect,
    component: () => import("/src/pages/catalogue.vue").then(m => m.default || m)
  },
  {
    name: index_46clientHkhDe08HgSMeta?.name ?? undefined,
    path: index_46clientHkhDe08HgSMeta?.path ?? "/checkout",
    meta: { ...(index_46clientHkhDe08HgSMeta || {}), ...{"middleware":["client"]} },
    alias: index_46clientHkhDe08HgSMeta?.alias || [],
    redirect: index_46clientHkhDe08HgSMeta?.redirect,
    component: () => createClientPage(() => import("/src/pages/checkout/index.client.vue").then(m => m.default || m)),
    children: [
  {
    name: index4E3N5yzW7cMeta?.name ?? "checkout",
    path: index4E3N5yzW7cMeta?.path ?? "",
    meta: { ...(index4E3N5yzW7cMeta || {}), ...{"middleware":["client"]} },
    alias: index4E3N5yzW7cMeta?.alias || [],
    redirect: index4E3N5yzW7cMeta?.redirect,
    component: () => import("/src/pages/checkout/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: paymentpRiiCb5uX2Meta?.name ?? "checkout-payment",
    path: paymentpRiiCb5uX2Meta?.path ?? "/checkout/payment",
    meta: { ...(paymentpRiiCb5uX2Meta || {}), ...{"middleware":["client"]} },
    alias: paymentpRiiCb5uX2Meta?.alias || [],
    redirect: paymentpRiiCb5uX2Meta?.redirect,
    component: () => import("/src/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: successwcsqw1E5wSMeta?.name ?? "checkout-success",
    path: successwcsqw1E5wSMeta?.path ?? "/checkout/success",
    meta: { ...(successwcsqw1E5wSMeta || {}), ...{"middleware":["client"]} },
    alias: successwcsqw1E5wSMeta?.alias || [],
    redirect: successwcsqw1E5wSMeta?.redirect,
    component: () => import("/src/pages/checkout/success.vue").then(m => m.default || m)
  },
  {
    name: contactjt1IjhjjXuMeta?.name ?? "contact",
    path: contactjt1IjhjjXuMeta?.path ?? "/contact",
    meta: { ...(contactjt1IjhjjXuMeta || {}), ...{"middleware":["client"]} },
    alias: contactjt1IjhjjXuMeta?.alias || [],
    redirect: contactjt1IjhjjXuMeta?.redirect,
    component: () => import("/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: deliveryPolicyV4Rtk0xb6MMeta?.name ?? "deliveryPolicy",
    path: deliveryPolicyV4Rtk0xb6MMeta?.path ?? "/deliveryPolicy",
    meta: { ...(deliveryPolicyV4Rtk0xb6MMeta || {}), ...{"middleware":["client"]} },
    alias: deliveryPolicyV4Rtk0xb6MMeta?.alias || [],
    redirect: deliveryPolicyV4Rtk0xb6MMeta?.redirect,
    component: () => import("/src/pages/deliveryPolicy.vue").then(m => m.default || m)
  },
  {
    name: deliveryPriceXrIE2dtqadMeta?.name ?? "deliveryPrice",
    path: deliveryPriceXrIE2dtqadMeta?.path ?? "/deliveryPrice",
    meta: { ...(deliveryPriceXrIE2dtqadMeta || {}), ...{"middleware":["client"]} },
    alias: deliveryPriceXrIE2dtqadMeta?.alias || [],
    redirect: deliveryPriceXrIE2dtqadMeta?.redirect,
    component: () => import("/src/pages/deliveryPrice.vue").then(m => m.default || m)
  },
  {
    name: faqytbxbScGkeMeta?.name ?? "faq",
    path: faqytbxbScGkeMeta?.path ?? "/faq",
    meta: { ...(faqytbxbScGkeMeta || {}), ...{"middleware":["client"]} },
    alias: faqytbxbScGkeMeta?.alias || [],
    redirect: faqytbxbScGkeMeta?.redirect,
    component: () => import("/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexCJfkQgdimFMeta?.name ?? "favorites",
    path: indexCJfkQgdimFMeta?.path ?? "/favorites",
    meta: { ...(indexCJfkQgdimFMeta || {}), ...{"middleware":["client"]} },
    alias: indexCJfkQgdimFMeta?.alias || [],
    redirect: indexCJfkQgdimFMeta?.redirect,
    component: () => import("/src/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: howtobuyoNLrI8NF4nMeta?.name ?? "howtobuy",
    path: howtobuyoNLrI8NF4nMeta?.path ?? "/howtobuy",
    meta: { ...(howtobuyoNLrI8NF4nMeta || {}), ...{"middleware":["client"]} },
    alias: howtobuyoNLrI8NF4nMeta?.alias || [],
    redirect: howtobuyoNLrI8NF4nMeta?.redirect,
    component: () => import("/src/pages/howtobuy.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index",
    path: indexvsa0KwJWqiMeta?.path ?? "/",
    meta: { ...(indexvsa0KwJWqiMeta || {}), ...{"middleware":["client"]} },
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexz0ZORWmtqyMeta?.name ?? "login",
    path: indexz0ZORWmtqyMeta?.path ?? "/login",
    meta: { ...(indexz0ZORWmtqyMeta || {}), ...{"middleware":["client"]} },
    alias: indexz0ZORWmtqyMeta?.alias || [],
    redirect: indexz0ZORWmtqyMeta?.redirect,
    component: () => import("/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45doL8H9laQMNNMeta?.name ?? "login-reset-do",
    path: reset_45doL8H9laQMNNMeta?.path ?? "/login/reset-do",
    meta: { ...(reset_45doL8H9laQMNNMeta || {}), ...{"middleware":["client"]} },
    alias: reset_45doL8H9laQMNNMeta?.alias || [],
    redirect: reset_45doL8H9laQMNNMeta?.redirect,
    component: () => import("/src/pages/login/reset-do.vue").then(m => m.default || m)
  },
  {
    name: reset2oRE49G5qqMeta?.name ?? "login-reset",
    path: reset2oRE49G5qqMeta?.path ?? "/login/reset",
    meta: { ...(reset2oRE49G5qqMeta || {}), ...{"middleware":["client"]} },
    alias: reset2oRE49G5qqMeta?.alias || [],
    redirect: reset2oRE49G5qqMeta?.redirect,
    component: () => import("/src/pages/login/reset.vue").then(m => m.default || m)
  },
  {
    name: logoutJAIe3EGR5DMeta?.name ?? "logout",
    path: logoutJAIe3EGR5DMeta?.path ?? "/logout",
    meta: { ...(logoutJAIe3EGR5DMeta || {}), ...{"middleware":["client"]} },
    alias: logoutJAIe3EGR5DMeta?.alias || [],
    redirect: logoutJAIe3EGR5DMeta?.redirect,
    component: () => import("/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: _202406_45shipment6erSHsj8boMeta?.name ?? "news-202406-shipment",
    path: _202406_45shipment6erSHsj8boMeta?.path ?? "/news/202406-shipment",
    meta: { ...(_202406_45shipment6erSHsj8boMeta || {}), ...{"middleware":["client"]} },
    alias: _202406_45shipment6erSHsj8boMeta?.alias || [],
    redirect: _202406_45shipment6erSHsj8boMeta?.redirect,
    component: () => import("/src/pages/news/202406-shipment.vue").then(m => m.default || m)
  },
  {
    name: cancelYQ53yrpTKjMeta?.name ?? "payment-cancel",
    path: cancelYQ53yrpTKjMeta?.path ?? "/payment/cancel",
    meta: { ...(cancelYQ53yrpTKjMeta || {}), ...{"middleware":["client"]} },
    alias: cancelYQ53yrpTKjMeta?.alias || [],
    redirect: cancelYQ53yrpTKjMeta?.redirect,
    component: () => import("/src/pages/payment/cancel.vue").then(m => m.default || m)
  },
  {
    name: successGMygimXkW8Meta?.name ?? "payment-success",
    path: successGMygimXkW8Meta?.path ?? "/payment/success",
    meta: { ...(successGMygimXkW8Meta || {}), ...{"middleware":["client"]} },
    alias: successGMygimXkW8Meta?.alias || [],
    redirect: successGMygimXkW8Meta?.redirect,
    component: () => import("/src/pages/payment/success.vue").then(m => m.default || m)
  },
  {
    name: privacyPolicyHrlvlkjJ6DMeta?.name ?? "privacyPolicy",
    path: privacyPolicyHrlvlkjJ6DMeta?.path ?? "/privacyPolicy",
    meta: { ...(privacyPolicyHrlvlkjJ6DMeta || {}), ...{"middleware":["client"]} },
    alias: privacyPolicyHrlvlkjJ6DMeta?.alias || [],
    redirect: privacyPolicyHrlvlkjJ6DMeta?.redirect,
    component: () => import("/src/pages/privacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93E94BWUgd9ZMeta?.name ?? "products-id",
    path: _91id_93E94BWUgd9ZMeta?.path ?? "/products/:id()",
    meta: { ...(_91id_93E94BWUgd9ZMeta || {}), ...{"middleware":["client"]} },
    alias: _91id_93E94BWUgd9ZMeta?.alias || [],
    redirect: _91id_93E94BWUgd9ZMeta?.redirect,
    component: () => import("/src/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: indexcyRXhHrH9pMeta?.name ?? "products",
    path: indexcyRXhHrH9pMeta?.path ?? "/products",
    meta: { ...(indexcyRXhHrH9pMeta || {}), ...{"middleware":["client"]} },
    alias: indexcyRXhHrH9pMeta?.alias || [],
    redirect: indexcyRXhHrH9pMeta?.redirect,
    component: () => import("/src/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexoOhunJ3bITMeta?.name ?? "register",
    path: indexoOhunJ3bITMeta?.path ?? "/register",
    meta: { ...(indexoOhunJ3bITMeta || {}), ...{"middleware":["client"]} },
    alias: indexoOhunJ3bITMeta?.alias || [],
    redirect: indexoOhunJ3bITMeta?.redirect,
    component: () => import("/src/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: returnPolicyhvXIBy7uTPMeta?.name ?? "returnPolicy",
    path: returnPolicyhvXIBy7uTPMeta?.path ?? "/returnPolicy",
    meta: { ...(returnPolicyhvXIBy7uTPMeta || {}), ...{"middleware":["client"]} },
    alias: returnPolicyhvXIBy7uTPMeta?.alias || [],
    redirect: returnPolicyhvXIBy7uTPMeta?.redirect,
    component: () => import("/src/pages/returnPolicy.vue").then(m => m.default || m)
  },
  {
    name: saleseVNbyOZ5hSMeta?.name ?? "sales",
    path: saleseVNbyOZ5hSMeta?.path ?? "/sales",
    meta: { ...(saleseVNbyOZ5hSMeta || {}), ...{"middleware":["client"]} },
    alias: saleseVNbyOZ5hSMeta?.alias || [],
    redirect: saleseVNbyOZ5hSMeta?.redirect,
    component: () => import("/src/pages/sales.vue").then(m => m.default || m)
  },
  {
    name: index6KfTDieHyuMeta?.name ?? "search",
    path: index6KfTDieHyuMeta?.path ?? "/search",
    meta: { ...(index6KfTDieHyuMeta || {}), ...{"middleware":["client"]} },
    alias: index6KfTDieHyuMeta?.alias || [],
    redirect: index6KfTDieHyuMeta?.redirect,
    component: () => import("/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: termsOfUseCYLALZXHHqMeta?.name ?? "termsOfUse",
    path: termsOfUseCYLALZXHHqMeta?.path ?? "/termsOfUse",
    meta: { ...(termsOfUseCYLALZXHHqMeta || {}), ...{"middleware":["client"]} },
    alias: termsOfUseCYLALZXHHqMeta?.alias || [],
    redirect: termsOfUseCYLALZXHHqMeta?.redirect,
    component: () => import("/src/pages/termsOfUse.vue").then(m => m.default || m)
  },
  {
    name: tipsAdvicekJ7fK5YR7BMeta?.name ?? "tipsAdvice",
    path: tipsAdvicekJ7fK5YR7BMeta?.path ?? "/tipsAdvice",
    meta: { ...(tipsAdvicekJ7fK5YR7BMeta || {}), ...{"middleware":["client"]} },
    alias: tipsAdvicekJ7fK5YR7BMeta?.alias || [],
    redirect: tipsAdvicekJ7fK5YR7BMeta?.redirect,
    component: () => import("/src/pages/tipsAdvice.vue").then(m => m.default || m)
  },
  {
    name: whyusMwyGXzZgcsMeta?.name ?? "whyus",
    path: whyusMwyGXzZgcsMeta?.path ?? "/whyus",
    meta: { ...(whyusMwyGXzZgcsMeta || {}), ...{"middleware":["client"]} },
    alias: whyusMwyGXzZgcsMeta?.alias || [],
    redirect: whyusMwyGXzZgcsMeta?.redirect,
    component: () => import("/src/pages/whyus.vue").then(m => m.default || m)
  }
]