<template>
  <NuxtLayout>
    <NuxtErrorBoundary @error="errorHandler">
      <NuxtPage />
    </NuxtErrorBoundary>
  </NuxtLayout>
</template>

<script lang="ts" setup>
const errorHandler = (e: any) => {
  console.error('app', e)
}
</script>