import { createI18n } from 'vue-i18n';
import en from '../locales/en.yml';
import zh from '../locales/zh-CN.yml';

export default defineNuxtPlugin((nuxtApp) => {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'en', // Default locale
    messages: {
      en,
      zh,
    },
  });

  nuxtApp.vueApp.use(i18n);
});

// function loadLocales() {
//   const locales: Record<string, any> = {};
//   const localeFiles = ['en.yml', 'zg-CN.yml'];

//   localeFiles.forEach((file) => {
//     const locale = file.replace('.yml', '');
//     const filePath = `/locales/${file}`;

//     const fileContents = parseYamlFile(filePath);

//     locales[locale] = fileContents;
//   });

//   return locales;
// }

// async function parseYamlFile(filePath: string): Promise<any> {
//   try {
//     if (typeof window === 'undefined') {
//       // Server-side
//       const fs = require('fs');
//       const fileContent = fs.readFileSync(filePath, 'utf8'); // Use your server-side file reading method
//       const parsedContent = yaml.load(fileContent);
//       return parsedContent;
//     } else {
//       // Client-side
//       const response = await fetch(filePath);
//       const fileContent = await response.text();
//       const parsedContent = yaml.load(fileContent);
//       return parsedContent;
//     }
//   } catch (error) {
//     console.error(`Error parsing YAML file "${filePath}":`, error);
//     return {};
//   }
// }
